<template>
  <div class="drivers-step4-content">
    <!-- 认证提交 -->
    <div v-if="[DriverAtuhStatus.Unknow, DriverAtuhStatus.Waiting, null].includes(status)" class="content">
      <img class="img" src="@assets/tjcg.png" />
      <p class="fw-600">
        认证已提交
      </p>
      <p class="reason">请耐心等待审核结果</p>
      <Button class="mt-42" @click="handleReload" type="primary">刷新</Button>
    </div>

    <!-- 认证成功 -->
    <div v-if="status === DriverAtuhStatus.Finish" class="content">
      <img class="img" src="@assets/tjcg.png" />
      <p class="fw-600">
        认证成功
      </p>
      <p class="reason">请继续提交车辆认证</p>
      <Button class="mt-42" @click="handleRouteTo(PageEnum.BASE_CAR_ADD)" type="primary">车辆认证</Button>
      <p class="font-16 color-primary mt-24" @click="handleRouteTo(PageEnum.BASE_HOME)">
        暂不认证
      </p>
    </div>

    <!-- 认证失败 -->
    <div v-if="status === DriverAtuhStatus.Fail" class="content">
      <img class="img" src="@assets/tjsb.png" />
      <p class="fail-title">
        认证失败
      </p>
      <p class="reason">原因：</p>
      <ul style="color: red">
        <li v-for="(item, index) in userVerifiedStore.verifiedInfo.refuseReason" :key="index">
          {{ item.remarks }}
        </li>
      </ul>
      <Button class="mt-42" @click="handleReset" type="primary">重新认证</Button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import { defineComponent, onBeforeUnmount, onMounted, computed } from '@vue/composition-api';
import { Button, Popup } from 'vant';
import { useDriverVerifiedStore } from '@/store/driverVerfied';
import { DriverAtuhStatus } from '@/constants';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    Button,
    Popup
  },
  setup() {
    const userVerifiedStore = useDriverVerifiedStore();
    const useInfoStore = useUserStore();
    // 改变页面背景颜色
    onMounted(() => {
      document.querySelector('body').setAttribute('style', 'background-color:#fff');
    });

    const router = useRouter();
    // 认证状态
    // TODO: reson数据需要进行二次处理
    const status = computed(() => userVerifiedStore?.verifiedInfo?.authStatus);
    // 失败原因map
    const refuseReason = computed(() => userVerifiedStore?.verifiedInfo?.refuseReason);

    // 更新当前状态
    const handleReload = async () => {
      userVerifiedStore.queryVerifiedInfo();
    };

    // 重新认证
    const handleReset = () => {
      // 重置步骤已经司机验证状态
      userVerifiedStore.queryVerifiedInfo(() => {
        userVerifiedStore.updateInfo({
          step: 0
        });
      });
    };

    // 卸载组件前
    onBeforeUnmount(() => {
      document.querySelector('body').removeAttribute('style');
    });

    return {
      status,
      refuseReason,
      PageEnum,
      handleReload,
      handleReset,
      DriverAtuhStatus,
      userVerifiedStore,
      handleRouteTo: async path => {
        if (path === PageEnum.BASE_CAR_ADD) {
          // 如果是跳转到车辆页store中的数据需要更新
          await useInfoStore.getUserInfoAction();
          setTimeout(() => {
            // 更新完成之后再做跳转
            router.push(path);
          }, 300);
        } else {
          router.push(path);
        }
      }
    };
  }
});
</script>
<style lang="less" scoped>
@import url('../styles/step4.less');
</style>
<style lang="less"></style>
